.pagination {
  display: flex;

  & > * {
    padding: 5px;
    margin: 3px;
    min-width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(3px);
    @include transition(background-color, 0.2s, ease);

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($default, 0.05);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.active {
      background-color: $success;

      &:hover {
        cursor: default;
      }

      & > * {
        color: $white;
      }
    }

    & > * {
      font-size: $fz-13;
    }
  }
}
