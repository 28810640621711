@import './mixins.scss';
* {
  outline: none !important;
}

html,
body {
  margin: 0;

  padding: 0;

  min-width: 100%;
  width: 100%;
  max-width: 100%;

  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

html {
  -webkit-overflow-scrolling: touch;
  font-size: 100%;
}
body {
  font-family: $roboto;
  color: $default;
  font-size: $fz-16;
  font-weight: $roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box {
  margin: 60px 0;
  padding: 60px;
  border-top: 1px solid rgba($default, 0.2);
  background-color: rgba($default, 0.03);
}

.no-events {
  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }
}

figure {
  margin: 0;
  margin-top: 20px;
  width: 100%;

  pre {
    font-size: 14px;
  }
}

mark {
  padding: 2px 4px;
  font-size: $fz-14;
  font-weight: $medium;
  color: $danger;
  background-color: rgba($warning, 0.3);
  border-radius: 3px;
  display: inline-block;

  mark {
    padding: 6px 12px;
    margin: 2px 0;
    background-color: rgba($warning, 0.5);
    & + mark {
      margin-top: 2px;
    }
  }
}

.highlight {
  padding: 9px 14px;
  margin-bottom: 14px;
  background-color: #e1e1e8;
  border-radius: 4px;
  color: #000;
}

.landing {
  width: 100%;
}

kbd {
  font-weight: $bold;
  padding: 2px 6px;
  font-size: $fz-14;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

code {
  padding: 2px 4px;
  font-weight: $regular;
  color: $danger;
  background-color: #ffe8ee;
  margin: 0 5px;
  @include border-radius(4px);
}

.dl-horizontal {
  dt {
    @media (min-width: $screen-md-min) {
      float: left;
      width: 160px;
      overflow: hidden;
      clear: left;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 24px;
    }
  }

  dd {
    @media (min-width: $screen-md-min) {
      margin-left: 180px;
      display: flex;
      align-items: center;
      min-height: 24px;
    }
  }
}

sup {
  top: 0;
  color: $gray;
  margin-left: 5px;
  font-weight: 600;
  font-size: $fz-13;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8f98a8;
  font-size: $fz-12;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #8f98a8;
  font-size: $fz-12;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #8f98a8;
  font-size: $fz-12;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #8f98a8;
  font-size: $fz-12;
}

.loader {
  border: 2px solid #fff;
  border-top: 2px solid #020b43;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  pointer-events: none;

  &.fullfilled {
    width: 25px;
    height: 25px;
    border: 2px solid $color-secondary;
    border-top: 2px solid $default;
  }

  &.white {
    width: 70px;
    height: 70px;
    border: 4px solid rgba(0, 0, 0, 0.2);
    border-top: 4px solid #fff;
  }

  &.absolute {
    position: absolute;
    z-index: 3;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $color-secondary;
  border-color: $color-secondary transparent $color-secondary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none !important;
}

.unactive {
  opacity: 0.3;
}

.half-opacity {
  opacity: 0.5;
}

input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.relative {
  position: relative;
}

.display-none {
  display: none !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}

.table-loader {
  td {
    div {
      color: transparent;
      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
      background-size: 500px 100px;
      animation-name: moving-gradient;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;

      svg {
        visibility: hidden;
      }
    }
  }
}

.empty-breadcrumbs {
  .link {
    color: transparent;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    height: 20px;
    width: 100px;

    svg {
      visibility: hidden;
    }
  }
}
.empty-breadcrumbs:hover {
  .link {
    color: transparent;
    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    height: 20px;
    width: 100px;

    svg {
      visibility: hidden;
    }
  }
}
@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

select {
  &.capitalize {
    text-transform: capitalize;
  }
}

.capitalize {
  text-transform: capitalize;
}

.combined-inputs {
  .form-group {
    margin: 0;

    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
}

select:disabled,
input:disabled {
  opacity: 1;
}

.accountThompsons {
  max-width: 100% !important;
  flex-basis: 100% !important;
  justify-content: center;
}

.no-within {
  z-index: 1001 !important;
  // z-index: 9999 !important;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.red-text {
  color: red;
}

.linking-block {
  display: flex;
  justify-content: space-evenly;
  width: 70px;
}

.main-color {
  color: var(--main-color);
}

.unset-text-transform {
  text-transform: unset;
}

.field {
  &-border-radius {
    border-radius: 3px;
  }
}

.remove-entity-btn {
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  background: $white;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.1s linear;

  &:hover {
    opacity: 1;
  }
}

.form-control-wrapper .form-control-container .new-design-field {
  border: 1px solid var(--main-color) !important;
  // border: 1px solid #191b36;
  border-radius: 15px !important;

  &:hover {
    box-shadow: 3px 5px 5px var(--main-color) !important;
  }
}

.form-control-wrapper .form-control-container .new-design-placeholder {
  &::placeholder {
    color: rgba($primary, 0.7);
  }
}

.form-control-select-wrapper .form-control-select-group .new-design-field {
  border: 1px solid var(--main-color) !important;
  // border: 1px solid #191b36;
  border-radius: 15px !important;

  &:hover {
    box-shadow: 3px 5px 5px var(--main-color);
  }
}

.form-group .new-design-field {
  border: 1px solid var(--main-color);
  // border: 1px solid #191b36;
  border-radius: 15px !important;

  &:hover {
    box-shadow: 3px 5px 5px var(--main-color);
  }
}

.form-group .new-design-google-field {
  border: 1px solid var(--main-color);
  // border: 1px solid #191b36;
  border-radius: 15px 15px 0 0 !important;

  &:hover {
    box-shadow: 3px 5px 5px var(--main-color);
  }
}

.link h4.new-design-task-name {
  font-size: 1.5rem;
  margin-left: 5px;

  &:hover {
    text-shadow: 0.5px 0.5px 1px $primary;
    // text-shadow: 0.5px 0.5px #050e3b;
    transform: translate(-0.5px, -0.5px);
  }
}

.main-controls h2.new-design-create-claim {
  font-size: 1.5rem;
}

.new-design-back-icon {
  &:hover {
    transform: translateX(-3px);
    cursor: pointer;
  }
}

.form-control-label .ellipsis span.new-design-prompt {
  color: rgba($primary, 0.7);
}

.form-control-select-label-inside
  .form-control-select-label
  .pointer-without-events
  span.new-design-prompt {
  color: rgba($primary, 0.7);
}

.form-control-label span.new-design-prompt {
  color: rgba($primary, 0.7);
}

.new-design-button {
  &:hover {
    box-shadow: 3px 5px 5px var(--main-color);
    transform: translate(-0.5px, -0.5px);
  }
}

.new-design-icon-button {
  &:hover {
    box-shadow: 3px 5px 5px var(--main-color);
    transform: translate(-0.5px, -0.5px);
  }
}
